import Vue from 'vue'
import VueToastify from "vue-toastify";
import moment from 'vue-moment';
import App from './App.vue'
import './assets/styles/index.css'
import http from './services/http';
import Pagination from 'vue-pagination-2';
import VModal from 'vue-js-modal';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

Vue.component('pagination', Pagination);
Vue.use(VModal, { componentName: 'modal' })
Vue.use(VueToastify);
Vue.use(moment);
Vue.config.productionTip = false
Vue.prototype.$http = http;

import router from './router'
import store from './store';

Vue.mixin({
  data() {
    return {
      api: '',
      jwt: '',
      residuos: [
        "Entulho",
        "Gesso",
        "Papel/plástico",
        "Papelão",
        "Madeira",
        "Tintas",
        "Contaminado",
        "Metal",
      ],
    }
  },
  methods: {
    hasAccess(roles) {
      if (roles.indexOf(store.state.user.role) > -1) {
        return true;
      }

      return false;
    },
  },
  beforeMount() {
    this.api = process.env.VUE_APP_API;
    this.jwt = localStorage.getItem("token");
  },
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
