<template>
  <div class="min-h-screen bg-gray-100 flex flex-col justify-center sm:py-12">
    <div class="p-10 xs:p-0 mx-auto md:w-full md:max-w-md">
      <div class="grid mb-5">
        <label class="text-xl text-center font-semibold tracking-widest text-gray-900 uppercase rounded-lg dark-mode:text-white focus:outline-none focus:shadow-outline">Plataforma Circular</label>
      </div>
      <div class="bg-white shadow w-full rounded-lg divide-y divide-gray-200">
        <div class="px-5 py-7">
          <label class="font-semibold text-sm text-gray-600 pb-1 block">E-mail</label>
          <input v-model="form.email" type="email" class="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full" />
          <label class="font-semibold text-sm text-gray-600 pb-1 block">Senha</label>
          <input v-model="form.password" type="password" class="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full" />
          <button @click="login" type="button" class="transition duration-200 bg-green-500 hover:bg-green-600 focus:bg-green-700 focus:shadow-sm focus:ring-4 focus:ring-green-500 focus:ring-opacity-50 text-white w-full py-2.5 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block">
              <span class="inline-block mr-2">Login</span>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-4 h-4 inline-block">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
              </svg>
          </button>
        </div>
      </div>
    </div>
  
  </div>
</template>

<script>

export default {
  name: 'Login',
  components: {
  },
  data: function () {
    return {
      form: {
        email: '',
        password: ''
      },
    }
  },
  methods: {
    async login() {
      const resp = await this.$http.post('/login', this.form);

      if (!resp.data.success) {
        this.$vToastify.error(resp.data.err);
      } else {
        this.err = false;

        localStorage.setItem('token', resp.data.token);
        localStorage.setItem('user', JSON.stringify(resp.data.user));
        console.log("resp.data.user", resp.data.user);
        this.$store.commit('login', resp.data.user);
        this.$router.push({path:'/obras'});
      }
    }
  },
}
</script>
