import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/',
    redirect: '/obras',
  },

  {
    path: '/graficos',
    name: 'Graficos',
    component: () => import('../views/graficos/index.vue'),
    meta: {requiresLogin: true},
  },

  {
    path: '/agualuz',
    name: 'Agualuz',
    component: () => import('../views/agualuz/index.vue'),
    meta: {requiresLogin: true},
  },
  {
    path: '/agualuz/form',
    name: 'agualuzForm',
    component: () => import('../views/agualuz/form.vue'),
    meta: {requiresLogin: true},
  },
  {
    path: '/agualuz/form/:id',
    name: 'agualuzFormEdit',
    component: () => import('../views/agualuz/form.vue'),
    meta: {requiresLogin: true},
  },

  {
    path: '/qtdfunc',
    name: 'qtdfunc',
    component: () => import('../views/qtdfunc/index.vue'),
    meta: {requiresLogin: true},
  },
  {
    path: '/qtdfunc/form',
    name: 'qtdfuncForm',
    component: () => import('../views/qtdfunc/form.vue'),
    meta: {requiresLogin: true},
  },
  {
    path: '/qtdfunc/form/:id',
    name: 'qtdfuncFormEdit',
    component: () => import('../views/qtdfunc/form.vue'),
    meta: {requiresLogin: true},
  },

  {
    path: '/obras',
    name: 'Obras',
    component: () => import('../views/obras/index.vue'),
    meta: {requiresLogin: true},
  },
  {
    path: '/obras/form',
    name: 'obrasForm',
    component: () => import('../views/obras/form.vue'),
    meta: {requiresLogin: true},
  },
  {
    path: '/obras/form/:id',
    name: 'obrasFormEdit',
    component: () => import('../views/obras/form.vue'),
    meta: {requiresLogin: true},
  },

  {
    path: '/users',
    name: 'Usuarios',
    component: () => import('../views/users/index.vue'),
    meta: {requiresLogin: true},
  },
  {
    path: '/users/form',
    name: 'UsuariosForm',
    component: () => import('../views/users/form.vue'),
    meta: {requiresLogin: true},
  },
  {
    path: '/users/form/:id',
    name: 'UsuariosEdit',
    component: () => import('../views/users/form.vue'),
    meta: {requiresLogin: true},
  },
  {
    path: '/users/historico/:id',
    name: 'UsuariosHistorico',
    component: () => import('../views/users/historico.vue'),
    meta: {requiresLogin: true},
  },


  {
    path: '/empresas',
    name: 'Empresas',
    component: () => import('../views/empresas/index.vue'),
    meta: {requiresLogin: true},
  },
  {
    path: '/empresas/form',
    name: 'EmpresasForm',
    component: () => import('../views/empresas/form.vue'),
    meta: {requiresLogin: true},
  },
  {
    path: '/empresas/form/:id',
    name: 'EmpresasEdit',
    component: () => import('../views/empresas/form.vue'),
    meta: {requiresLogin: true},
  },


  {
    path: '/residuos',
    name: 'Residuos',
    component: () => import('../views/residuos/index.vue'),
    meta: {requiresLogin: true},
  },
  {
    path: '/residuos/form',
    name: 'residuosForm',
    component: () => import('../views/residuos/form.vue'),
    meta: {requiresLogin: true},
  },
  {
    path: '/residuos/form/:id',
    name: 'residuosFormEdit',
    component: () => import('../views/residuos/form.vue'),
    meta: {requiresLogin: true},
  },

  {
    path: '/documentos',
    name: 'Documentos',
    component: () => import('../views/documentos/index.vue'),
    meta: {requiresLogin: true},
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const hasToken = localStorage.getItem('token');
  if (hasToken && !store.state.isLogged) {
    store.commit('login', JSON.parse(localStorage.getItem('user')));
    next();
  } else if (to.meta.requiresLogin && !store.state.isLogged) {
    next({name: 'Login'});
  } else if (to.name === 'Login' && hasToken && store.state.isLogged) {
    next(from);
  } else {
    next();
  }
});

export default router;
