<template>
  <div id="app" class="md:grid md:grid-cols-12">
    <Menu class="col-span-4 md:col-span-3 xl:col-span-2" v-if="$store.state.isLogged" />
    
    <div :class="{'col-span-8 md:col-span-9 xl:col-span-10': $store.state.isLogged, 'col-span-12': !$store.state.isLogged,}">
      <router-view />
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Menu";

export default {
  components: {
    Menu,
  },
};
</script>
