import Vuex from 'vuex';
import Vue from 'vue';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    isLogged: false,
    token: false,
    user: {},
    imagem: {
      originalname: '',
      filename: '',
      mimetype: '',
    },
  },
  mutations: {
    setImagem(state, imagem) {
      state.imagem = imagem;
    },
    login(state, user) {
      state.isLogged = true;
      state.token = localStorage.getItem('token');
      state.user = {...user};
    },
    logout(state) {
      state.isLogged = false;
      state.token = false;
      state.user = {};
    },
  },
});

export default store;
